var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { attrs: { id: "app" } },
          [
            _c(
              "agile",
              {
                attrs: {
                  "nav-buttons": false,
                  "autoplay-speed": 5000,
                  speed: 2500,
                  fade: "fade",
                  "pause-on-hover": "pause-on-hover",
                  "pause-on-dots-hover": "pause-on-dots-hover",
                  autoplay: "autoplay"
                }
              },
              [
                _c("img", {
                  staticClass: "slide",
                  attrs: {
                    src:
                      "https://images.unsplash.com/photo-1509549649946-f1b6276d4f35?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "slide",
                  attrs: {
                    src:
                      "https://images.unsplash.com/photo-1511469054436-c7dedf24c66b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjEyMDd9"
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "slide",
                  attrs: {
                    src:
                      "https://images.unsplash.com/photo-1511135232973-c3ee80040060?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjEyMDd9"
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "slide",
                  attrs: {
                    src:
                      "https://images.unsplash.com/photo-1511231683436-44735d14c11c?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjEyMDd9"
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "slide",
                  attrs: {
                    src:
                      "https://images.unsplash.com/photo-1517677129300-07b130802f46?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjEyMDd9"
                  }
                })
              ]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }