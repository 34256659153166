import { render, staticRenderFns } from "./HomeComponent.vue?vue&type=template&id=07b81154&"
import script from "./HomeComponent.vue?vue&type=script&lang=js&"
export * from "./HomeComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/lampp/htdocs/corporate/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07b81154')) {
      api.createRecord('07b81154', component.options)
    } else {
      api.reload('07b81154', component.options)
    }
    module.hot.accept("./HomeComponent.vue?vue&type=template&id=07b81154&", function () {
      api.rerender('07b81154', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/frontend/components/HomeComponent.vue"
export default component.exports